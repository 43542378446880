a+a {
  margin-left: 35px;
}

#intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 8%;
  padding-top: 3%;
  min-height: 80vh;
}

#intro-gallery {
  display: flex;
  flex-direction: row; /* Arrange children side by side */
  justify-content: center; /* Center-align them horizontally */
  align-items: center; /* Align them vertically */
  gap: 20px; /* Add space between them */
}



.intro-desc {
  color: var(--slate);
  font-family: "NTR", sans-serif;
  font-size: 22px;
  max-width: 40%;
  text-align: left;
  padding-bottom: 50px;

}

.intro-desc {
  padding-top: 4%;
  margin-top: 50px;
  margin-bottom: 35px;
  font-size: 18px;
  text-align: justify;
  font-family: "NTR", sans-serif;
  color: var(--slate);
  max-width: 600px;
  font-size: 22px;
  text-align: justify;
  margin: 0;
}


.intro-image {
  padding-left: 60px;
  /* max-width: 300px; */
}

.intro-image img {
  max-width: 600px;
  /* max-height: 1024px; */
  height: auto;
  border-radius: 10px;
  margin-left: 30px;
  box-shadow: 6px 13px 23px -5px rgba(0, 0, 0, 0.46);
  border: none;
}

.intro-gallery2 {
  max-width: 1000px;
  height: 200px;
  padding-top: 4%;
  padding-left: 70px;
}

.intro-gallery2 img {
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  margin-left: 30px;
  box-shadow: 6px 13px 23px -5px rgba(0, 0, 0, 0.46);
  border: none;
}

.intro-video1 {
  /* padding-top: 4%; */
  padding-left: 70px;
}

.intro-video1 video {
  
  max-width: 600px;
  max-height: 1024px;
  height: auto;
  border-radius: 10px;
  margin-left: 30px;
  box-shadow: 6px 13px 23px -5px rgba(0, 0, 0, 0.46);
  border: none;
}

.intro-image2 {
  max-width: 500px;
  height: 200px;
  padding-top: 4%;
  padding-left: 70px;
}

.intro-image2 video {
  
  max-width: 300px;
  max-height: 1024px;
  height: auto;
  border-radius: 10px;
  margin-left: 30px;
  box-shadow: 6px 13px 23px -5px rgba(0, 0, 0, 0.46);
  border: none;
}

.intro-image3 {
  padding-top: 4%;
  padding-left: 20px;
}

.intro-image3 video {
  max-width: 300px;

  max-height: 1024px;
  height: auto;
  border-radius: 10px;
  margin-left: 5px;
  box-shadow: 6px 13px 23px -5px rgba(0, 0, 0, 0.46);
  border: none;
}


@media only screen and (max-width: 788px) {
  .intro-image img {
    max-width: 200px;
  }
}

.intro-image:hover {
  transform: translateY(-7px);
}

canvas {
  width: 5px !important;
}

.Typist {
  color: var(--lightest-slate);
  text-align: left;
  font-size: 76px;
  margin-top: -30px;
}
.Typist .Cursor--blinking {
  color: var(--green-bright);
  padding-left: 4px;
}

.intro-title {
  font-family: "NTR", sans-serif;
  font-size: 86px;
}
.intro-name {
  font-family: "NTR", sans-serif;
  color: var(--green-bright);
  font-weight: bold;
  font-size: 86px;
}

.intro-subtitle {
  margin-top: -36px;
  font-family: "NTR", sans-serif;
  color: var(--slate);
  font-weight: 300;
  font-size: 48px;
}


.intro-contact {
  font-size: 22px;
  font-weight: bolder;
  font-family: "NTR", sans-serif;
  padding: 10px 30px;
  cursor: pointer;
  border: 1px solid var(--green-bright);
  border-radius: 10px;
  border-spacing: 150px;
}

.intro-contact:hover {
  background-color: var(--lightest-navy);
}



@media only screen and (max-width: 788px) {
  .intro-contact img {
    max-width: 200px;
  }
}

@media only screen and (max-width: 700px) {
  #gazi-model {
    display: none;
  }
  #intro {
    height: unset;
    padding-left: unset;
    padding-right: unset;
    padding-top: 2%;
  }
  /* .intro-image {
    padding-left: 70px;
  } */
  
  .intro-image img {
    width:100%;
    /* height: 300px; */
    border-radius: 20px;
    margin-left: -5%;
    margin-top: 45px;
    box-shadow: 6px 13px 23px -5px rgba(0, 0, 0, 0.46);
    border: none;
  }
  .intro-image2 img {
    width:100%;
    /* height: 300px; */
    border-radius: 20px;
    margin-left: -15%;
    margin-top: 25px;
    box-shadow: 6px 13px 23px -5px rgba(0, 0, 0, 0.46);
    border: none;
  }
  
  .intro-title {
    font-family: "NTR", sans-serif;
    font-size: 40px;
  }
  .intro-name {
  font-family: "NTR", sans-serif;
  color: var(--green-bright);
  font-weight: bold;
  font-size: 40px;
}
  .intro-subtitle {
    margin-top: -18px;
    font-family: "NTR", sans-serif;
    color: var(--slate);
    font-weight: 300;
    font-size: 20px;
  }


  .contact-div {
  padding-top: 50%;
  margin-left: 15%;
  }
}
