:root {
  --dark-navy: #020c1b;
  --navy: #e9e9e991;
  --light-navy: #9ac2d4;
  --lightest-navy: #b4b4b4;
  --navy-shadow: rgba(235, 235, 235, 0.7);
  --slate: #000000;
  --light-slate: #696969;
  --lightest-slate: #000000;
  --white: #e6f1ff;
  --green-bright: #04658b;
  --green-folder: #00e1ff;
  --green-tint: rgba(158, 22, 22, 0.1);
}


/* :root {
  --dark-navy: #020c1b;
    --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --navy-shadow: rgba(2, 12, 27, 0.7);
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green-bright: #64ffda;
  --green-tint: rgba(100, 255, 218, 0.1);
} */
