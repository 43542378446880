#experience {
  padding-left: 8%;
  padding-bottom: 10%;
}

.joblist-job-title {
  font-family: "NTR", sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: var(--lightest-slate);
}

.joblist-job-company {
  font-family: "NTR", sans-serif;
  font-size: 28px;
  color: var(--green-bright);
  font-weight: bold;
}

.joblist-duration {
  font-family: "NTR", sans-serif;
  font-size: 18px;
  color: var(--slate);
}

#vertical-tabpanel {
  margin-top: -28px;
  padding-left: 25px;
}

.MuiTab-wrapper {
  font-size: 16px;
  text-align: left;
  align-items: flex-start !important;
  font-family: "NTR", sans-serif;
}

.MuiButtonBase-root {
  outline: none !important;
  color: var(--lightest-slate) !important;
}

.PrivateTabIndicator-colorSecondary-5 {
  background-color: var(--green-bright) !important;
}
.jss5 {
  background-color: var(--green-bright) !important;
} 
.makeStyles-tabs-2 {
  border-right: 1px solid var(--lightest-navy) !important;
}
#experience .MuiTab-root {
  padding: 6px 20px;
}
.MuiButtonBase-root:focus {
  color: var(--green-bright) !important;
}

ul {
  list-style: none;
  margin-left: -40px;
}

.job-description li::before {
  content: "▹    ";
  color: var(--green-bright);
  position: absolute;
  left: 0;
  font-size: 18px;
  
}

.job-description li {
  position: relative;
  padding-left: 30px;
  padding-bottom: 16px;
  font-size: 22px;
  color: var(--slate);
}

.job-description {
  padding-top: 24px;
  max-width: 800px;
  font-family: "NTR", sans-serif;
  font-size: 20px;
}

.job-description a,
.job-description b {
  font-size: 19px;
  font-weight: bold;
}

.logo-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 10px;
}

.logo-grid-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.logo-grid-item {
  width: 80px; /* Adjust size as needed */
  height: auto;
  margin-bottom: 8px;
  transition: transform 0.3s ease;
}

.logo-grid-description {
  font-size: 14px;
  color: #333; /* Adjust color as needed */
}

.logo-grid-item:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 600px) {
  #experience {
    padding-left: unset;
    padding-right: unset;
  }

  .jss1 {
    height: unset !important;
    flex-direction: column !important;
  }

  .makeStyles-root-1 {
    height: unset !important;
    width: unset;
    flex-direction: column;
  }

  .joblist-job-title {
    font-size: 22px;
  }

  .joblist-job-company {
    font-size: 22px;
  }

}
